import { Stack, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.primary.main,
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.primary.main,
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));
const ToggleButton = ({ value, setValue }) => {
	const handleChange = (e) => {
		localStorage.setItem("isListView", JSON.stringify(!value));
		setValue(e.target.checked);
	};
	return (
		<Stack direction="row" spacing={1} alignItems="center">
			<Typography variant="body1" color="text.primary">
				Asc
			</Typography>
			<IOSSwitch checked={value} onChange={handleChange} />
			<Typography variant="body1" color="text.primary">
				Dsc
			</Typography>
		</Stack>
	);
};

export default ToggleButton;

import Close from "@mui/icons-material/Close";
import {
	Box,
	Button,
	CircularProgress,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { disableEditMode } from "../../../../redux/notification/notificationSlice";
import EnhancedTableHead from "../../../common/EnhancedTableHead";
import TablePaginationActions from "../../../common/TablePaginationActions";

export default function PettyCashesDataTable({
	height = "calc(100vh - 400px)",
	rows = [],
	categories,
	headings = [],
	loading,
	columns,
	updateFields = {},
	total,
	rowsPerPage,
	setRowsPerPage,
	page,
	setPage,
}) {
	const dispatch = useDispatch();
	const [editableIndex, setEditableIndex] = React.useState(-1);
	const { isEditMode } = useSelector((state) => state.notification);
	const emptyRows = rows.length < rowsPerPage ? rowsPerPage - rows.length : 0;
	const paginationPage = Number.parseInt(page) - 1;
	const handleChangePage = (event, newPage) => {
		setPage(newPage + 1);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(Number.parseInt(event.target.value, 10));
		setPage(0);
	};
	const SingleTableRow = ({ row, index }) => {
		// const handleDoubleClick = () => {
		//   setEditableIndex(index);
		//   dispatch(enableEditMode());
		// };
		return (
			<TableRow
				hover
				tabIndex={-1}
				//  onDoubleClick={handleDoubleClick}
			>
				{headings?.map(({ id, align }) => (
					<TableCell key={id} align={align} sx={{ py: "5px" }}>
						{editableIndex === index && isEditMode ? (
							<>
								<input type="hidden" name="pettyCashId" value={row._id} />
								{id === "pvc" ? (
									<>
										<input type="hidden" name="pvc" value={row[id]} />
										{row[id]}
									</>
								) : id === "date" ? (
									<TextField
										defaultValue={row[id]}
										type="date"
										required
										size="small"
										margin="dense"
										sx={{ width: 140 }}
										fullWidth
										name="date"
									/>
								) : // <TextField defaultValue={new Date(row[id]).toLocaleDateString('en-CA')} type="date" required size='small' margin='dense' sx={{ width: 140 }} name='date' /> :
								id === "category" ? (
									<TextField
										defaultValue={row?.category?._id}
										required
										size="small"
										margin="dense"
										select
										sx={{ width: 130 }}
										name="category"
									>
										{categories.map(({ _id, name }) => (
											<MenuItem value={_id} key={_id}>
												{name}
											</MenuItem>
										))}
									</TextField>
								) : id === "amount" ? (
									<TextField
										inputProps={{ step: 0.01 }}
										defaultValue={row[id]}
										size="small"
										margin="dense"
										name="amount"
										type="number"
										sx={{ width: 100 }}
										required
									/>
								) : id === "description" ? (
									<TextField
										defaultValue={row[id]}
										size="small"
										margin="dense"
										name="description"
										type="text"
										sx={{ width: 100 }}
									/>
								) : id === "action" ? (
									<>
										<Button
											variant="contained"
											size="small"
											sx={{ color: "#fff" }}
											type="submit"
										>
											Update
										</Button>
										<Button
											onClick={() => dispatch(disableEditMode())}
											color="error"
										>
											<Close />{" "}
										</Button>
									</>
								) : updateFields[id] ? (
									updateFields[id](row)
								) : (
									row[id]
								)}
							</>
						) : updateFields[id] ? (
							updateFields[id](row)
						) : (
							row[id]
						)}
					</TableCell>
				))}
			</TableRow>
		);
	};
	return (
		<Paper sx={{ width: "100%", mb: 2, mt: 3 }} elevation={0}>
			<TableContainer>
				<Table stickyHeader>
					<EnhancedTableHead
						columns={columns}
						headings={headings}
						rowCount={rows.length}
					/>
					{loading ? (
						<Box
							sx={{
								position: "absolute",
								top: "40vh",
								left: "50%",
								transform: "translateX(-50%)",
								zIndex: 2,
							}}
						>
							<CircularProgress />
						</Box>
					) : rows.length > 0 ? (
						<TableBody>
							{rows?.map((row, index) => (
								<SingleTableRow key={index} row={row} index={index} />
							))}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					) : (
						<TableBody>
							<TableRow>
								<TableCell colSpan={headings.length} align="center">
									<Typography variant="h4">No Records In The List!</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					)}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 50, 100, { label: "All", value: total }]}
				component="div"
				count={total}
				rowsPerPage={rowsPerPage}
				page={paginationPage}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
				labelRowsPerPage="Rows:"
			/>
		</Paper>
	);
}

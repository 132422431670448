import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	addNewGroup,
	addNewOffer,
	addNewSaleProduct,
	addNewService,
	getAllGroups,
	getAllOffers,
	getAllSaleProduct,
	getAllService,
} from "./saleproductAPI";

const initialState = {
	allSaleProduct: [],
	isLoading: false,
	isAllLoading: false,
	service: [],
	groups: [],
	offers: [],
	fetch: 0,
	SaleProductItemFetch: 0,
};

export const getSaleProductList = createAsyncThunk(
	"saleproduct/list",
	async (params) => {
		const data = await getAllSaleProduct(params);
		return data;
	},
);

export const addSaleProduct = createAsyncThunk(
	"saleproduct/create",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await addNewSaleProduct(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const addService = createAsyncThunk(
	"saleproduct/service/create",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await addNewService(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const getService = createAsyncThunk(
	"saleproduct/service/list",
	async (payload) => {
		const data = await getAllService(payload);
		return data;
	},
);

export const addGroup = createAsyncThunk(
	"saleproduct/group/create",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await addNewGroup(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const getGroups = createAsyncThunk(
	"saleproduct/group/list",
	async (payload) => {
		const data = await getAllGroups(payload);
		return data;
	},
);

export const addOffer = createAsyncThunk(
	"saleproduct/offer-type/create",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await addNewOffer(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const getOffers = createAsyncThunk(
	"saleproduct/offer/list",
	async (payload) => {
		const data = await getAllOffers(payload);
		return data;
	},
);
const saleproductReducer = createSlice({
	name: "saleproduct",
	initialState,

	extraReducers: (builder) => {
		builder
			.addCase(getSaleProductList.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(getSaleProductList.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.allSaleProduct = payload.saleproduct;
			})
			.addCase(getSaleProductList.rejected, (state) => {
				state.isLoading = false;
			});
		builder
			.addCase(addSaleProduct.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(addSaleProduct.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.fetch += 1;
			})
			.addCase(addSaleProduct.rejected, (state) => {
				state.isLoading = false;
			});
		builder
			.addCase(addService.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(addService.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.SaleProductItemFetch += 1;
				state.service = payload.service;
			})
			.addCase(addService.rejected, (state) => {
				state.isLoading = false;
			});
		builder
			.addCase(addGroup.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(addGroup.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.SaleProductItemFetch += 1;
				state.groups = payload.groups;
			})
			.addCase(addGroup.rejected, (state) => {
				state.isLoading = false;
			});
		builder
			.addCase(addOffer.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(addOffer.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.SaleProductItemFetch += 1;
				state.offers = payload.offers;
			})
			.addCase(addOffer.rejected, (state) => {
				state.isLoading = false;
			});

		builder.addCase(getService.fulfilled, (state, { payload }) => {
			state.service = payload.service;
		});
		builder.addCase(getGroups.fulfilled, (state, { payload }) => {
			state.groups = payload.groups;
		});
		builder.addCase(getOffers.fulfilled, (state, { payload }) => {
			state.offers = payload.offers;
		});
	},
});

export default saleproductReducer.reducer;

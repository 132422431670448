import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import {
	Box,
	Button,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { closeModal } from "../../../../redux/notification/notificationSlice";
import { getSingleOutlet } from "../../../../redux/outlets/outletsSlice";
import {
	getPettyCashes,
	updatePettyCash,
} from "../../../../redux/pettycashes/pettycashesSlice";
import { getAllCategory } from "../../../../redux/productCategory/productCategorySlice";
import currency from "../../../../utils/currencyFormat";
import CustomModal from "../../../common/CustomModal";
import PrimaryButton from "../../../common/PrimaryButton";
import ActionMenu from "../ActionMenu";
import AddPettyCashAmount from "./AddPettyCashAmount";
import AddPettyCashes from "./AddPettyCashes";
import PettyCashViewReport from "./PettyCashViewReport";
import PettyCashesDataTable from "./PettyCashesDataTable";

const PettyCashesTable = ({
	dateFilter,
	setDateFilter,
	category,
	setCategory,
}) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { categories } = useSelector((state) => state.categories);
	const { pettycashes, total, isLoading, fetch, isBulkUpdating } = useSelector(
		(state) => state.pettycashes,
	);
	const { outletDetails, fetch: outletDetailsFetch } = useSelector(
		(state) => state.outlets,
	);

	const [selectedPettycashes] = useState([]);

	const [page, setPage] = useState(1);

	const [rowsPerPage, setRowsPerPage] = useState(25);
	const handleDateFilter = (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const values = {
			startDate: data.get("startDate"),
			endDate: data.get("endDate"),
		};
		setDateFilter(values);
		dispatch(closeModal());
	};
	useEffect(() => {
		dispatch(getSingleOutlet(id));
	}, [dispatch, id, outletDetailsFetch, fetch]);

	useEffect(() => {
		dispatch(getAllCategory());
	}, [dispatch]);

	useEffect(() => {
		const params = {
			page: page,
			size: rowsPerPage,
			category,
			outlet: id,
			...dateFilter,
		};
		if (category === "all") {
			delete params.category;
		}
		dispatch(getPettyCashes(params));
	}, [dispatch, page, category, fetch, id, dateFilter, rowsPerPage]);

	useEffect(() => {
		setPage(1);
	}, [rowsPerPage]);

	const handleSelect = (e) => {
		if (e.target.checked) {
			selectedPettycashes((pre) => [...pre, e.target.value]);
		} else {
			const rest = selectedPettycashes.filter((id) => id !== e.target.value);
			selectedPettycashes(rest);
		}
	};

	const handleEdit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const data = {
			pvc: formData.get("pvc"),
			date: formData.get("date"),
			category: formData.get("category"),
			description: formData.get("description"),
			amount: formData.get("amount"),
		};
		dispatch(updatePettyCash({ id: formData.get("pettyCashId"), data }));
	};

	const headings = [
		// {
		//     id: 'sl',
		//     align: "left",
		//     label: '#',
		// },
		{
			id: "pvc",
			align: "left",
			label: "INVOICE NUMBER",
			required: true,
		},
		{
			id: "date",
			align: "left",
			label: "DATE",
			required: true,
		},
		{
			id: "category",
			align: "left",
			label: "CATEGORIES",
		},
		{
			id: "description",
			align: "left",
			label: "DESCRIPTION",
		},
		{
			id: "amount",
			align: "right",
			label: "AMOUNT",
		},
		{
			id: "action",
			align: "right",
			label: "Action",
		},
	];
	const getPettyCashAmount = (amount) => {
		if (amount) {
			if (amount < 0) {
				return `-RM${Math.abs(amount)}`;
			}
			return `RM${amount}`;
		}
		return null;
	};
	return (
		<Box>
			<Box className="flex justify-end md:hidden md:mb-0 mb-2">
				<PettyCashViewReport dateFilter={dateFilter} category="all" />
			</Box>
			<Box className="min-[1080px]:flex min-[1080px]:justify-between items-center min-[1080px]:space-y-0 space-y-2.5">
				<Box
					sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
				>
					<TextField
						label="Filter by Category"
						onChange={(e) => setCategory(e.target.value)}
						value={category}
						select
						size="small"
						name="category"
						sx={{ minWidth: 180, mr: 2 }}
					>
						<MenuItem value="all">All</MenuItem>
						{categories?.map(({ _id, name }) => (
							<MenuItem key={_id} value={_id}>
								{" "}
								{name}
							</MenuItem>
						))}
					</TextField>

					<CustomModal
						sx={{ maxWidth: 300 }}
						title="Date Filter"
						action={
							<Button
								variant="outlined"
								sx={{
									textTransform: "capitalize",
									color: "gray",
									borderColor: "#0000003b",
									"&:hover": { borderColor: "secondary.main" },
								}}
							>
								Filter Date
							</Button>
						}
					>
						<Box component={"form"} onSubmit={handleDateFilter}>
							<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
								Start Date
							</Typography>
							<TextField
								defaultValue={
									dateFilter.startDate || new Date().toISOString().split("T")[0]
								}
								type="date"
								required
								size="small"
								margin="dense"
								fullWidth
								name="startDate"
							/>
							<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
								End Date
							</Typography>
							<TextField
								defaultValue={
									dateFilter.endDate || new Date().toISOString().split("T")[0]
								}
								type="date"
								required
								size="small"
								margin="dense"
								fullWidth
								name="endDate"
							/>
							<PrimaryButton sx={{ mt: 2 }} type="submit">
								Filter
							</PrimaryButton>
						</Box>
					</CustomModal>
					{dateFilter.startDate && (
						<IconButton onClick={() => setDateFilter({})}>
							<Close />
						</IconButton>
					)}
				</Box>

				<Box sx={{ display: "flex", gap: 2.5, justifyContent: "end" }}>
					<Box
						sx={{
							background: "#fff",
							borderRadius: "4px",
							boxShadow: "0px 0px 51px 5px rgba(0, 0, 0, 0.04)",
							display: "flex",
							alignItems: "center",
							px: "10px",
							gap: "10px",
						}}
					>
						<Typography
							sx={{
								color: "#636363",
								fontSize: { xs: 13, md: 16 },
								fontWeight: 500,
							}}
						>
							Remaining petty cash:{" "}
							{getPettyCashAmount(outletDetails?.pettyCashAmount)}
						</Typography>
						<CustomModal
							sx={{ maxWidth: 520, zIndex: 1000 }}
							title={
								outletDetails?.pettyCashAmount
									? "Edit petty cash"
									: "Add petty cash"
							}
							action={
								<Typography
									sx={{
										textTransform: "uppercase",
										fontSize: 14,
										color: "#62B73A",
										textDecoration: "underline",
										fontWeight: 600,
										cursor: "pointer",
									}}
								>
									{outletDetails?.pettyCashAmount ? "Edit" : "Add"}
								</Typography>
							}
						>
							<AddPettyCashAmount
								pettyCashAmount={outletDetails?.pettyCashAmount}
								outlet={id}
							/>
						</CustomModal>
					</Box>

					<CustomModal
						sx={{ maxWidth: 1200, zIndex: 1000 }}
						title={"Add Expenses"}
						action={
							<Button startIcon={<AddIcon />} variant="outlined">
								Expenses
							</Button>
						}
					>
						<AddPettyCashes categories={categories} outlet={id} />
					</CustomModal>
				</Box>
			</Box>

			{/* <Box component={"form"} onSubmit={handleEdit}> */}
			<PettyCashesDataTable
				categories={categories}
				headings={headings}
				rows={pettycashes}
				total={total}
				page={page}
				loading={isBulkUpdating || isLoading}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
				setPage={setPage}
				updateFields={{
					category: (item) => item.category?.name,
					date: (item) => (
						<>{new Date(item.date).toLocaleDateString("en-MY")}</>
					),
					amount: (item) => <>{currency.format(item.amount)}</>,
					action: (item) => (
						<ActionMenu id={item._id} title="Petty Cash" edit item={item} />
					),
				}}
			/>
			{/* </Box> */}
		</Box>
	);
};

export default PettyCashesTable;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import {
	addNewPettyCashes,
	bulkUpdate,
	deleteSinglePettyCash,
	getAllPettyCashList,
	getAllPettyCashes,
	getPettyCashSummary,
	updateSinglePettyCash,
} from "./pettycashesAPI";
const initialState = {
	pettycashes: [],
	allPettyCash: [],
	pettyCashSummary: [],
	pettyCashesDetails: {},
	isDetailsLoading: false,
	isBulkUpdating: false,
	total: 0,
	isLoading: false,
	isError: false,
	fetch: 0,
};

export const getPettyCashes = createAsyncThunk(
	"pettycashes/list",
	async (params) => {
		const data = await getAllPettyCashes(params);
		return data;
	},
);

export const getPettyCashList = createAsyncThunk(
	"pettycashes/list-all",
	async (params) => {
		const data = await getAllPettyCashList(params);
		return data;
	},
);
export const getSummaryPettyCashList = createAsyncThunk(
	"pettycashes/summary",
	async (params) => {
		const data = await getPettyCashSummary(params);
		return data;
	},
);

export const addPettyCashes = createAsyncThunk(
	"pettycashes/create",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await addNewPettyCashes(payload);
			return data;
		} catch (error) {
			if (error.response?.data?.message?.includes("E11000 duplicate key")) {
				Swal.fire(
					"Attention!",
					"The Invoice Number has been used for this supplier. Kindly key in another number.",
					"error",
				);
				return;
			}
			return rejectWithValue(error.response.data);
		}
	},
);

export const updatePettyCash = createAsyncThunk(
	"pettycashes/edit",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await updateSinglePettyCash(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
export const deletePettyCash = createAsyncThunk(
	"pettycashes/delete",
	async (id, { rejectWithValue }) => {
		try {
			const data = await deleteSinglePettyCash(id);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const bulkUpdatePettyCashes = createAsyncThunk(
	"pettycashes/bulk-update",
	async (payload) => {
		const data = await bulkUpdate(payload);
		return data;
	},
);

const outletReducer = createSlice({
	name: "pettycashes",
	initialState,

	extraReducers: (builder) => {
		builder
			.addCase(getPettyCashes.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(getPettyCashes.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.pettycashes = payload.data?.pettycashes;
				state.total = payload.data?.total;
			})
			.addCase(getPettyCashes.rejected, (state, { error }) => {
				state.isLoading = false;
			});
		builder
			.addCase(getPettyCashList.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(getPettyCashList.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.allPettyCash = payload.pettycashes;
			})
			.addCase(getPettyCashList.rejected, (state) => {
				state.isLoading = false;
			});
		builder
			.addCase(getSummaryPettyCashList.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(getSummaryPettyCashList.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.pettyCashSummary = payload.pettyCashSummary;
			})
			.addCase(getSummaryPettyCashList.rejected, (state) => {
				state.isLoading = false;
			});
		builder
			.addCase(updatePettyCash.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updatePettyCash.fulfilled, (state) => {
				state.isLoading = false;
				state.fetch += 1;
			})
			.addCase(updatePettyCash.rejected, (state) => {
				state.isLoading = false;
			});
		builder
			.addCase(deletePettyCash.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(deletePettyCash.fulfilled, (state) => {
				state.isLoading = false;
				state.fetch += 1;
			})
			.addCase(deletePettyCash.rejected, (state) => {
				state.isLoading = false;
			});

		builder.addCase(addPettyCashes.fulfilled, (state) => {
			state.fetch += 1;
		});

		builder
			.addCase(bulkUpdatePettyCashes.pending, (state) => {
				state.isBulkUpdating = true;
			})
			.addCase(bulkUpdatePettyCashes.fulfilled, (state, { payload }) => {
				state.isBulkUpdating = false;
				state.fetch += 1;
			})
			.addCase(bulkUpdatePettyCashes.rejected, (state, { error }) => {
				state.isBulkUpdating = false;
			});
	},
});

export default outletReducer.reducer;

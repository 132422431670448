import { axiosTokenInstance } from "../../utils/axios";

export const getAllBills = async (params) => {
	const { data } = await axiosTokenInstance.get("bills/list", {
		params: {
			...params,
		},
	});
	return data;
};
export const getAllListBills = async (params) => {
	const { data } = await axiosTokenInstance.get("bills/list-all", {
		params: {
			...params,
		},
	});
	return data;
};
export const getBillsSummary = async (params) => {
	/* data shuold pass like the following:
    {
        "outlet": "63d386bf41d533f25c67736e",
        "year": "2023", (if empty the year will auto get the current year)
        "month": "02"
    }
    by: Asher*/

	const { data } = await axiosTokenInstance.post("bills/summary", params);
	return data;
};

export const addNewBill = async (payload) => {
	const { data } = await axiosTokenInstance.post("bills/create", payload, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return data;
};
export const bulkUpdate = async (payload) => {
	const { data } = await axiosTokenInstance.patch("bills/bulk-update", payload);
	return data;
};
export const updateSingleBill = async (payload) => {
	const { data } = await axiosTokenInstance.patch(
		`bills/edit/${payload.id}`,
		payload.data,
		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		},
	);
	return data;
};

export const deleteBillItem = async (id) => {
	const { data } = await axiosTokenInstance.delete(`bills/delete/${id}`);
	return data;
};

import {
	Box,
	Grid,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSale } from "../../../../redux/sales/salesSlice";
import PrimaryButton from "../../../common/PrimaryButton";

const EditSale = ({ action, item }) => {
	const { allSaleProduct } = useSelector((state) => state.saleproduct);
	const dispatch = useDispatch();
	const [selectedSaleProductId, setSelectedSaleProductId] = useState(
		item?.saleproduct?._id || "",
	);
	const [selectedSaleProduct, setSelectedSaleProduct] = useState({});
	const [formData, setFormData] = useState({});

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		if (selectedSaleProductId) {
			const data = allSaleProduct.find(
				({ _id }) => _id === selectedSaleProductId,
			);
			setSelectedSaleProduct(data);
		}
	}, [selectedSaleProductId, allSaleProduct]);

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(updateSale({ id: item?._id, data: formData }));
	};
	return (
		<Box component={"form"} onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item md={7} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>Date</Typography>
					<TextField
						value={formData?.date}
						onChange={handleChange}
						defaultValue={item.date}
						type="date"
						required
						size="small"
						margin="dense"
						fullWidth
						name="date"
					/>
				</Grid>
				<Grid item md={7} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500, mb: 1 }}>
						Product
					</Typography>
					<TextField
						select
						fullWidth
						size="small"
						value={selectedSaleProductId}
						onChange={(e) => {
							setSelectedSaleProductId(e.target.value);

							setFormData({
								...formData,
								saleproduct: e.target.value,
							});
						}}
					>
						{allSaleProduct?.map(({ service, group, offerType, _id }) => (
							<MenuItem key={_id} value={_id}>{`${
								service && `${service?.name},`
							} ${group && `${group?.name},`} ${offerType?.name}`}</MenuItem>
						))}
					</TextField>
				</Grid>
				{
					<Grid item xs={12}>
						<TableContainer>
							<Table sx={{ "& th,td": { py: 1 } }}>
								<TableHead>
									<TableRow>
										<TableCell>Service</TableCell>
										<TableCell>Group</TableCell>
										<TableCell>Offer Type</TableCell>
										<TableCell>Amount (RM)</TableCell>
										<TableCell>Payment Type</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell>{selectedSaleProduct?.service?.name}</TableCell>
										<TableCell>{selectedSaleProduct?.group?.name}</TableCell>
										<TableCell>
											{selectedSaleProduct?.offerType?.name}
										</TableCell>
										<TableCell>
											<TextField
												inputProps={{ step: 0.01 }}
												required
												size="small"
												type="number"
												sx={{ width: 80 }}
												name="amount"
												value={formData?.amount}
												defaultValue={item?.amount || ""}
												onChange={handleChange}
											/>
										</TableCell>
										<TableCell>
											<TextField
												select
												required
												size="small"
												type="number"
												name="paymentType"
												sx={{ width: 100 }}
												value={formData?.paymentType}
												defaultValue={item?.paymentType || ""}
												onChange={handleChange}
											>
												<MenuItem value="cash">Cash</MenuItem>
												<MenuItem value="card">Card</MenuItem>
												<MenuItem value="prepaid">Prepaid</MenuItem>
											</TextField>
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				}
				{/* <Grid item xs={12}> */}
				{/* <Typography sx={{ fontSize: 18, fontWeight: 500 }}>Amount (RM)</Typography> */}
				{/* <TextField value={getTotal(Object.values(selectedServices || {}))} size='small' margin='dense' name="amount" type="number" fullWidth inputProps={
                            { readOnly: true, }
                        } /> */}
				{/* </Grid> */}
			</Grid>

			<Box sx={{ textAlign: "right", mt: 3 }}>
				<PrimaryButton sx={{ width: 150 }} type="submit">
					Update Sales
				</PrimaryButton>
			</Box>
		</Box>
	);
};

export default EditSale;

import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../components/common/CustomModal";
import DataTable from "../components/common/DataTable";
import MultiSelect from "../components/common/MultiSelect";
import PrimaryButton from "../components/common/PrimaryButton";
import Header from "../components/dashboard/Header";
import PageTitle from "../components/dashboard/PageTitle";
import ActionMenu from "../components/dashboard/Supplier/ActionMenu";
import AddSupplier from "../components/dashboard/Supplier/AddSupplier";
import { getAllProduct } from "../redux/products/productsSlice";
import {
	addNewSupplier,
	deleteSupplier,
	getSuppliers,
} from "../redux/suppliers/suppliersSlice";

const localStorageKey = "coolSuppliersTable";
const Suppliers = () => {
	const dispatch = useDispatch();
	const [columns, setColumns] = useState(
		JSON.parse(
			localStorage.getItem(localStorageKey) ||
				'["id","name", "email","phone", "product","status","action"]',
		),
	);
	const { suppliers, isLoading, total, fetch } = useSelector(
		(state) => state.suppliers,
	);
	const { currentUser } = useSelector((state) => state.auth);
	const { allProducts, isAllLoading } = useSelector((state) => state.products);

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [selectedProducts, setSelectedProducts] = useState([]);
	useEffect(() => {
		const params = { page: page, size: rowsPerPage };

		dispatch(getSuppliers(params));
	}, [dispatch, page, fetch, rowsPerPage]);

	useEffect(() => {
		dispatch(getAllProduct());
	}, [dispatch]);

	// if (users.isLoading || suppliers.isAllLoading) {
	//     return <h1>Loading...</h1>
	// }

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const products = selectedProducts.map(({ _id }) => _id);
		const supplierData = {
			id: data.get("id"),
			name: data.get("name"),
			email: data.get("email"),
			phone: data.get("phone"),
			products,
		};
		dispatch(addNewSupplier(supplierData));
	};

	const deleteHandler = (id) => {
		dispatch(deleteSupplier(id));
	};

	const headings = [
		// {
		//   id: "id",
		//   align: "left",
		//   label: "ID",
		//   required: true,
		// },
		{
			id: "name",
			align: "left",
			label: "NAME",
			required: true,
		},
		{
			id: "email",
			align: "left",
			label: "EMAIL",
		},
		{
			id: "phone",
			align: "left",
			label: "PHONE",
		},
		{
			id: "products",
			align: "left",
			label: "PRODUCTS",
		},
		// {
		//   id: "status",
		//   align: "left",
		//   label: "STATUS",
		// },
		{
			id: "action",
			align: "right",
			label: "Action",
			required: true,
		},
		// ...(currentUser?.role === "admin"
		//   ? [
		//     {
		//       id: "action",
		//       align: "right",
		//       label: "Action",
		//       required: true,
		//     },
		//   ]
		//   : []),
	];

	return (
		<Box>
			<Header>
				<div className="hidden lg:block items-center">
					<PageTitle>Supplier</PageTitle>
				</div>
			</Header>
			<Box className="m-6 space-y-5">
				<Box className="lg:hidden">
					<PageTitle>Supplier</PageTitle>
				</Box>
				<Box className="flex space-x-5 justify-between">
					<MultiSelect
						localStorageKey={localStorageKey}
						columns={columns}
						setColumns={setColumns}
						totalColumns={headings}
					/>

					<CustomModal
						title={"Add a new Supplier"}
						action={
							<PrimaryButton startIcon={<AddIcon />} sx={{ width: 150 }}>
								Add
							</PrimaryButton>
						}
					>
						<AddSupplier
							setSelectedProducts={setSelectedProducts}
							handleSubmit={handleSubmit}
							products={allProducts}
						/>
					</CustomModal>
				</Box>

				<DataTable
					height="calc(100vh - 280px)"
					columns={columns}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
					headings={headings}
					rows={suppliers}
					total={total}
					page={page}
					setPage={setPage}
					loading={isLoading}
					updateFields={{
						products: (item) => item.products?.length,
						status: (item) => (
							<Box
								sx={{
									color:
										item.status === "verified" ? "primary.main" : "inherit",
								}}
							>
								{item.status}
							</Box>
						),
						action: (item) => (
							<ActionMenu
								deleteHandler={deleteHandler}
								id={item._id}
								selectedProducts={selectedProducts}
								setSelectedProducts={setSelectedProducts}
								products={allProducts}
							/>
						),
					}}
				/>
			</Box>
		</Box>
	);
};

export default Suppliers;

import Close from "@mui/icons-material/Close";

import { Button, IconButton, MenuItem, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addGroup,
	addOffer,
	addService,
} from "../../../../redux/saleproduct/saleproductSlice";

const CategoryItem = ({ type, required, outlet }) => {
	const [value, setValue] = useState("");
	const [isCreateMode, setIsCreateMode] = useState(false);
	const dispatch = useDispatch();
	const { categoryItemFetch, service, groups, offers, isLoading } = useSelector(
		(state) => state.saleproduct,
	);
	const [category, setCategory] = useState(null);
	useEffect(() => {
		setIsCreateMode(false);
		if (type === "service") {
			setCategory(service);
		} else if (type === "group") {
			setCategory(groups);
		} else if (type === "offer") {
			setCategory(offers);
		}
	}, [categoryItemFetch, isLoading]);

	const addItemHandler = () => {
		if (type === "service") {
			dispatch(addService({ name: value, outlet }));
		} else if (type === "group") {
			dispatch(addGroup({ name: value, outlet }));
		} else if (type === "offer") {
			dispatch(addOffer({ name: value, outlet }));
		}
	};
	return (
		<Box
			sx={{
				mt: 2,
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<Box>
				<TextField
					name={type}
					required={required}
					size="small"
					select
					sx={{ minWidth: 200 }}
				>
					{category?.map(({ _id, name }) => (
						<MenuItem key={_id} value={_id}>
							{name}
						</MenuItem>
					))}
				</TextField>
				<Button onClick={() => setIsCreateMode(true)}>+ Add New</Button>
			</Box>
			{isCreateMode && (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<TextField
						onChange={(e) => setValue(e.target.value)}
						value={value}
						size="small"
						name="name"
					/>
					<Button
						variant="contained"
						sx={{ color: "#fff" }}
						disabled={!value}
						onClick={addItemHandler}
					>
						{" "}
						Create
					</Button>
					<IconButton color="error" onClick={() => setIsCreateMode(false)}>
						<Close />
					</IconButton>
				</Box>
			)}
		</Box>
	);
};

export default CategoryItem;

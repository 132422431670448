import AddIcon from "@mui/icons-material/Add";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomModal from "../components/common/CustomModal";
import DataTable from "../components/common/DataTable";
import MultiSelect from "../components/common/MultiSelect";
import PreLoader from "../components/common/PreLoader";
import PrimaryButton from "../components/common/PrimaryButton";
import ToggleButton from "../components/common/ToggleButton";
import Header from "../components/dashboard/Header";
import AddOutlet from "../components/dashboard/Outlet/AddOutlet";
import PopupMenu from "../components/dashboard/Outlet/PopupMenu";
import PageTitle from "../components/dashboard/PageTitle";
import {
	addOutlet,
	deleteOutlet,
	getOutlets,
} from "../redux/outlets/outletsSlice";
import { getSuppliersList } from "../redux/suppliers/suppliersSlice";
import { getAllUserList } from "../redux/users/userSlice";

const headings = [
	{
		id: "name",
		align: "left",
		label: "OUTLETS",
		required: true,
	},
	{
		id: "class",
		align: "left",
		label: "CLASS",
		required: true,
	},
	{
		id: "suppliers",
		align: "center",
		label: "SUPPLIERS",
	},
	{
		id: "services",
		align: "center",
		label: "SERVICES",
	},
	{
		id: "created_at",
		align: "left",
		label: "CREATED ON",
	},
	// {
	//     id: "pcAccount",
	//     align: "left",
	//     label: "PC ACCOUNT"
	// },
	{
		id: "action",
		align: "right",
		label: "ACTION",
		required: true,
	},
];
const localStorageKey = "coolOutletTable";
const Outlets = () => {
	const dispatch = useDispatch();
	const [columns, setColumns] = useState(
		JSON.parse(
			localStorage.getItem(localStorageKey) ||
				'["name", "class", "suppliers","services","created_at", "action"]',
		),
	);
	const { outlets, users, suppliers, auth } = useSelector((state) => state);
	const navigate = useNavigate();
	const [search, setSearch] = useState("");
	const [sort, setSort] = useState(false);
	const [sortBy, setSortBy] = useState("");
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedSuppliers, setSelectedSuppliers] = useState([]);
	useEffect(() => {
		const params = {
			page: page,
			size: rowsPerPage,
			search,
			sort: sort ? "dsc" : "asc",
			sortBy,
		};
		if (search === "") {
			delete params.search;
		}
		if (!sortBy) {
			delete params.sortBy;
		}
		dispatch(getOutlets(params));
	}, [dispatch, page, outlets.fetch, search, sort, sortBy, rowsPerPage]);

	useEffect(() => {
		dispatch(getAllUserList());
		dispatch(getSuppliersList());
	}, [dispatch]);

	if (
		auth.currentUser?.role === "outletManager" ||
		auth.currentUser?.role === "readonlyOutletManager"
	) {
		if (outlets.isLoading) {
			return <PreLoader />;
		}
		if (outlets.outlets?.length === 1) {
			navigate(`/dashboard/outlets/${outlets.outlets?.[0]?._id}?page=sales`);
		} else if (outlets.outlets?.length === 0) {
			return (
				<Typography
					sx={{
						textAlign: "center",
						fontSize: 30,
						lineHeight: 2,
						fontWeight: 600,
						mt: "40vh",
					}}
					variant="h3"
				>
					You have no Outlet assigned. <br /> Please contact Admin for more
					information
				</Typography>
			);
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const suppliers = selectedSuppliers?.map(({ _id }) => _id);
		const users = selectedUsers?.map(({ _id }) => _id);
		const outletData = {
			name: data.get("name"),
			class: data.get("class"),
			pcAccount: data.get("pcAccount"),
			users,
			suppliers,
		};
		dispatch(addOutlet(outletData));
	};

	let timeOutId;
	const handleSearch = (e) => {
		if (timeOutId) {
			clearTimeout(timeOutId);
		}
		timeOutId = setTimeout(() => {
			setSearch((e.target.value || "").toUpperCase());
			setPage(1);
		}, 500);
	};

	const deleteHandler = (id) => {
		dispatch(deleteOutlet(id));
	};

	return (
		<Box>
			<Header>
				<div className="hidden lg:block items-center">
					<PageTitle>Outlets</PageTitle>
				</div>
				{/* <Typography sx={{ maxWidth: 710 }}>Sub title</Typography> */}
			</Header>

			<Box className="m-6 space-y-5">
				<Box className="lg:hidden">
					<PageTitle>Outlets</PageTitle>
				</Box>
				<Box className="flex space-x-5 justify-between">
					<MultiSelect
						localStorageKey={localStorageKey}
						columns={columns}
						setColumns={setColumns}
						totalColumns={headings}
					/>
					<CustomModal
						title={"Add a new outlet"}
						action={
							<PrimaryButton startIcon={<AddIcon />} sx={{ width: 150 }}>
								Add
							</PrimaryButton>
						}
					>
						<AddOutlet
							handleSubmit={handleSubmit}
							users={users.allUsers}
							suppliers={suppliers.allSuppliers}
							setSelectedUsers={setSelectedUsers}
							setSelectedSuppliers={setSelectedSuppliers}
						/>
					</CustomModal>
				</Box>

				<Box className="md:flex justify-end space-x-0 space-y-5 md:space-y-0 md:space-x-5">
					<div className="flex justify-end">
						<ToggleButton value={sort} setValue={setSort} />
					</div>

					<div className="flex justify-between space-x-5">
						<TextField
							size="small"
							label="Sort By"
							value={sortBy}
							onChange={(e) => setSortBy(e.target.value)}
							className="w-full md:w-[200px]"
							select
						>
							<MenuItem value="">None</MenuItem>
							<MenuItem value="name">Name</MenuItem>
							<MenuItem value="class">Class</MenuItem>
						</TextField>
						<TextField
							onChange={handleSearch}
							size="small"
							label="Search"
							className="w-full  md:w-[200px]"
							// sx={{ minWidth: 200 }}
						/>
					</div>
				</Box>

				{suppliers.isAllLoading ? (
					<PreLoader />
				) : (
					<DataTable
						height="calc(90vh - 310px)"
						columns={columns}
						page={page}
						setPage={setPage}
						headings={headings}
						rows={outlets.outlets}
						total={outlets.total}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
						loading={outlets.isLoading}
						updateFields={{
							suppliers: (item) => `(${item.suppliers?.length})`,
							services: (item) => `(${item.sales})`,
							action: (item) => (
								<PopupMenu
									deleteHandler={deleteHandler}
									id={item._id}
									users={users.allUsers}
									suppliers={suppliers.allSuppliers}
									selectedUsers={selectedUsers}
									setSelectedUsers={setSelectedUsers}
									selectedSuppliers={selectedSuppliers}
									setSelectedSuppliers={setSelectedSuppliers}
								/>
							),
							created_at: (item) => moment(item.created_at).format("DD MMM YY"),
						}}
					/>
				)}
			</Box>
		</Box>
	);
};

export default Outlets;

import CloseIcon from "@mui/icons-material/Close";
import {
	Autocomplete,
	Box,
	Button,
	Grid,
	InputAdornment,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	disableEditMode,
	enableEditMode,
} from "../../../../redux/notification/notificationSlice";
import { updateProduct } from "../../../../redux/products/productsSlice";
import { twoDecimalValidator } from "../../../../utils/twoDecimalValidator";
import PrimaryButton from "../../../common/PrimaryButton";

const AddStockUsages = ({ handleSubmit, edit, item }) => {
	const { allProducts } = useSelector((state) => state.products);
	const { isEditMode } = useSelector((state) => state.notification);
	const options = allProducts?.map(({ name, price, _id, unit }) => ({
		label: name,
		price,
		_id,
		unit,
	}));

	const [product, setProduct] = useState({});
	const [price, setPrice] = useState(null);
	const [unit, setUnit] = useState(null);

	const handleProduct = (event, newValue) => {
		setProduct(newValue);
		if (newValue) {
			setUnit(newValue?.unit);
			setPrice(newValue.price);
		} else {
			setPrice(newValue);
		}
	};
	useEffect(() => {
		if (edit) {
			setUnit(item?.unit);
			setProduct(item?.product);
			setPrice(item?.price);
		}
	}, [edit, item]);
	const dispatch = useDispatch();
	const updateProductPrice = () => {
		if (product && price) {
			dispatch(updateProduct({ id: product._id, data: { price } }));
		}
	};

	return (
		<Box component={"form"} onSubmit={handleSubmit}>
			<Grid container spacing={1}>
				<Grid item md={8} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>Date</Typography>
					<TextField
						defaultValue={item?.date || new Date().toISOString().split("T")[0]}
						type="date"
						required
						size="small"
						margin="dense"
						fullWidth
						name="date"
					/>
				</Grid>
				<Grid item md={8} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Product
					</Typography>
					<Autocomplete
						size="small"
						id="combo-box-demo"
						onChange={handleProduct}
						options={options}
						defaultValue={item?.product ? [item?.product?.name] : null}
						fullWidth
						renderInput={(params) => <TextField {...params} />}
					/>
					<input name="product" value={product?._id} type="hidden" />
				</Grid>
				<Grid item md={8} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Quantity
					</Typography>

					<TextField
						InputProps={{
							step: "0.01",
							min: "0.01",
							endAdornment: (
								<InputAdornment position="start">{unit ?? ""}</InputAdornment>
							),
							inputMode: "numeric",
						}}
						onChange={twoDecimalValidator}
						defaultValue={item?.quantity}
						size="small"
						margin="dense"
						name="quantity"
						type="number"
						fullWidth
						required
					/>
				</Grid>
				<Grid item md={8} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Price per unit (RM)
					</Typography>
					{isEditMode ? (
						<Box sx={{ display: "flex", alignItems: "center" }}>
							<TextField
								onChange={(e) => setPrice(e.target.value)}
								value={price}
								size="small"
								margin="dense"
								type="number"
								fullWidth
							/>
							<Button
								sx={{ color: "#fff" }}
								variant="contained"
								onClick={updateProductPrice}
							>
								Update
							</Button>{" "}
							<Button onClick={() => dispatch(disableEditMode())} color="error">
								<CloseIcon />{" "}
							</Button>
						</Box>
					) : (
						<TextField
							onDoubleClick={() => dispatch(enableEditMode())}
							disabled
							value={(price || product?.price)?.toFixed(2) || ""}
							size="small"
							margin="dense"
							type="number"
							fullWidth
							required
						/>
					)}
					<input name="price" value={price || ""} type="hidden" />
				</Grid>
			</Grid>

			<Box sx={{ textAlign: "right", mt: 3 }}>
				<PrimaryButton sx={{ width: 150 }} type="submit">
					Save
				</PrimaryButton>
			</Box>
		</Box>
	);
};

export default AddStockUsages;

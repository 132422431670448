import { axiosTokenInstance } from "../../utils/axios";

export const getAllPettyCashes = async (params) => {
	const { data } = await axiosTokenInstance.get("pettycashes/list", {
		params: {
			...params,
		},
	});
	return data;
};
export const getAllPettyCashList = async (params) => {
	const { data } = await axiosTokenInstance.get("pettycashes/list-all", {
		params: {
			...params,
		},
	});
	return data;
};
export const getPettyCashSummary = async (payload) => {
	const { data } = await axiosTokenInstance.post(
		"pettycashes/summary",
		payload,
	);
	return data;
};
export const addNewPettyCashes = async (payload) => {
	const { data } = await axiosTokenInstance.post("pettycashes/create", payload);
	return data;
};
export const bulkUpdate = async (payload) => {
	const { data } = await axiosTokenInstance.patch(
		"pettycashes/bulk-update",
		payload,
	);
	return data;
};
export const updateSinglePettyCash = async (payload) => {
	const { data } = await axiosTokenInstance.patch(
		`pettycashes/edit/${payload.id}`,
		payload.data,
	);
	return data;
};
export const deleteSinglePettyCash = async (id) => {
	const { data } = await axiosTokenInstance.delete(`pettycashes/delete/${id}`);
	return data;
};

import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Avatar,
	Box,
	Divider,
	Drawer,
	IconButton,
	Link,
	List,
	Toolbar,
	Typography,
	styled,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/images/logo/Caterpro_logo.svg";
import NavigationMenu from "./NavigationMenu";

const MuiAppBar = styled(AppBar)(({ theme }) => ({
	height: 80,
	position: "static",
	backgroundColor: theme.palette.background.paper,
	boxShadow: "none",
}));

const Header = ({ children, isOutletItem = false, outletName, userName }) => {
	const [open, setOpen] = useState(false);
	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};

	const HeaderDrawer = (
		<Drawer open={open} onClose={toggleDrawer(false)} className="lg:!hidden">
			<Box
				sx={{ width: 250, margin: "30px 0px" }}
				role="presentation"
				onClick={toggleDrawer(false)}
			>
				<List sx={{ pt: 0 }} component="nav">
					<NavigationMenu open={open} />
				</List>
			</Box>
		</Drawer>
	);

	return (
		<MuiAppBar>
			{HeaderDrawer}
			<Toolbar
				sx={{
					height: 80,
					px: "24px",
					justifyContent: "space-between",
				}}
			>
				<div className="flex space-x-2.5 items-center justify-between">
					<IconButton className="lg:!hidden" onClick={toggleDrawer(true)}>
						<MenuIcon />
					</IconButton>
					<Box>{children}</Box>
				</div>
				<div className="items-center text-center space-y-2.5">
					<Link
						className="lg:hidden items-center flex justify-center"
						href="/dashboard"
					>
						<img width={100} height={50} src={logo} alt="Logo" />
					</Link>
					{isOutletItem && (
						<div className="lg:hidden">
							<Typography
								sx={{
									fontWeight: 600,
									textTransform: "capitalize",
								}}
							>
								{outletName}
							</Typography>
							{/* <div>User assigned: {userName}</div> */}
						</div>
					)}
				</div>

				<Avatar />
			</Toolbar>
			<Divider />
		</MuiAppBar>
	);
};

export default Header;

import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
	Box,
	Button,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import CustomModal from "../../../common/CustomModal";

import {
	getBillList,
	getBillSummary,
} from "../../../../redux/bills/billsSlice";

import { useLocation } from "react-router-dom";
import currency from "../../../../utils/currencyFormat";
import { getMonthStartEnd } from "../../../../utils/getMonthDates";
import DownloadExcel from "../../../common/DownloadExcel";

// get monthly data for multiple data
const getMonthlyData = (data, year, month) => {
	const dates = getMonthStartEnd(year, month);
	const startDay = new Date(dates.firstDate).getDate();
	const endDay = new Date(dates.lastDate).getDate();
	const monthArray = [];
	for (let i = startDay; i <= endDay; i++) {
		monthArray.push({ date: i });
	}
	const monthlyData = monthArray.map((singleItem) => {
		const isDataExist = data?.filter(
			(item) => new Date(item.date).getDate() === singleItem.date,
		);
		if (isDataExist) {
			return { date: singleItem.date, data: [...isDataExist] };
		}
		return { date: singleItem.date, data: [{ ...singleItem }] };
	});
	const result = [];
	for (let i = 0; i < 4; i++) {
		const week = monthlyData.slice(
			i * 7,
			i === 3 ? monthlyData.length : i * 7 + 7,
		);
		result.push(week);
	}
	return result;
};

const BillsViewReport = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const tableRef = useRef(null);
	const { outletDetails } = useSelector((state) => state.outlets);
	const { currentUser } = useSelector((state) => state.auth);
	const page = search?.split("?page=")?.[1];

	const { billsSummary, fetch, allBills } = useSelector((state) => state.bills);

	const outletID = outletDetails?._id;
	const [month, setMonth] = useState(
		`${new Date().getMonth() + 1}`.padStart(2, "0"),
	);
	const [year, setYear] = useState(new Date().getFullYear());

	useEffect(() => {
		const outlet = outletID;
		const params = { outlet, year, month };
		dispatch(getBillSummary(params));
	}, [dispatch, year, month, outletID, fetch]);

	useEffect(() => {
		const outlet = outletID;
		const params = { outlet };
		dispatch(getBillList(params));
	}, [dispatch, outletID]);

	const pettyCashTableRef = useRef();
	const pettyCashTableRef2 = useRef();

	const title = document.title;
	const fileName = `Purchase-${new Date().toLocaleDateString("en-MY")}`;
	// group by date for multiple data
	const groupByDate = (arr) => {
		const billsWithDate = arr.reduce((acc, obj) => {
			const key = obj.date;
			if (!acc[key]) {
				acc[key] = [];
			}
			acc[key].push(...obj.data);
			return acc;
		}, {});
		return billsWithDate;
	};

	const groupData = getMonthlyData(billsSummary, year, month) || [];

	const getTotal = (arr) => {
		return arr.reduce((acc, curr) => (curr.amount || 0) + acc, 0);
	};

	const excelBody = allBills?.map(
		({ photo, invoice, amount, date, status, supplier }) => {
			if (amount === 0) {
				return null;
			}
			return {
				date: new Date(date).toLocaleDateString("en-MY"),
				invoice,
				supplier: supplier?.name,
				amount: currency.format(amount),
				status,
			};
		},
	);

	// const excelBody = billsSummary?.map(
	//   ({ photo, invoice, amount, date, status, supplier }) => {
	//     if (amount === 0) {
	//       return null;
	//     }
	//     return {
	//       date: new Date(date).toLocaleDateString("en-MY"),
	//       invoice,
	//       supplier: supplier?.name,
	//       amount: currency.format(amount),
	//       status,
	//     };
	//   }
	// );

	const PrintTable = React.forwardRef((props, ref) => {
		return (
			<div ref={ref}>
				<Typography
					variant="h3"
					sx={{
						fontSize: 26,
						fontWeight: 600,
						mb: "5px",
						textTransform: "capitalize",
					}}
				>
					{outletDetails?.name} {page ? `> ${page}` : ""}
				</Typography>
				<Typography sx={{ mb: 1 }}>
					User assigned: {currentUser?.firstName} {currentUser?.lastName}
				</Typography>
				<div className="flex space-x-5 mb-5">
					<TextField
						size="small"
						value={month}
						onChange={(e) => setMonth(e.target.value)}
						sx={{ width: 140 }}
						select
					>
						<MenuItem value="01">January</MenuItem>
						<MenuItem value="02">February</MenuItem>
						<MenuItem value="03">March</MenuItem>
						<MenuItem value="04">April</MenuItem>
						<MenuItem value="05">May</MenuItem>
						<MenuItem value="06">June</MenuItem>
						<MenuItem value="07">July</MenuItem>
						<MenuItem value="08">August</MenuItem>
						<MenuItem value="09">September</MenuItem>
						<MenuItem value="10">October</MenuItem>
						<MenuItem value="11">November</MenuItem>
						<MenuItem value="12">December</MenuItem>
					</TextField>
					<TextField
						size="small"
						value={year}
						onChange={(e) => setYear(e.target.value)}
						sx={{ width: 140 }}
						select
					>
						{[...new Array(5).keys()].map((_e, i) => {
							const year = new Date().getFullYear();
							return (
								<MenuItem key={year - i} value={year - i}>
									{year - i}
								</MenuItem>
							);
						})}
					</TextField>
				</div>
				{billsSummary.length === 0 ? (
					<Typography variant="h3" sx={{ textAlign: "center", my: 10 }}>
						No Data Found!
					</Typography>
				) : (
					<table ref={tableRef}>
						<thead>
							<tr>
								<th align="center"> Date </th>
								<th align="center"> Supplier </th>
								<th align="center"> Amount </th>
								<th align="center"> Total </th>
							</tr>
						</thead>
						<tbody>
							{groupData?.map((weeklyData, i) => {
								const subTotal = weeklyData?.reduce(
									(prev, curr) =>
										prev +
										(curr?.data?.reduce(
											(prev, curr) => prev + (curr.amount || 0),
											0,
										) || 0),
									0,
								);
								const data = groupByDate(weeklyData);
								return (
									<Fragment key={i}>
										{Object.keys(data)?.map((key) => {
											const total = data[key]?.reduce(
												(pre, curr) => pre + (curr.amount || 0),
												0,
											);
											return (
												<Fragment key={key}>
													{data[key]?.length > 0 ? (
														data[key]?.map(({ _id, amount, supplier }, i) => (
															<tr key={_id}>
																{i === 0 && (
																	<td
																		sx={{
																			borderRight: "1px solid currentColor",
																		}}
																		align="center"
																		rowSpan={data[key]?.length}
																	>
																		{" "}
																		{key}{" "}
																	</td>
																)}
																<td align="center"> {supplier?.name} </td>
																<td align="right">
																	{" "}
																	{currency.format(amount)}{" "}
																</td>
																{i === 0 && (
																	<td align="right" rowSpan={data[key]?.length}>
																		{" "}
																		{currency.format(total)}{" "}
																	</td>
																)}
															</tr>
														))
													) : (
														<tr>
															<td align="center"> {key} </td>
															<td align="center"> - </td>
															<td align="center"> - </td>
															<td align="center"> - </td>
														</tr>
													)}
												</Fragment>
											);
										})}
										<tr>
											<td colSpan={3} align="center">
												S/Total
											</td>
											<th align="right">{currency.format(subTotal)}</th>
										</tr>
									</Fragment>
								);
							})}
							<tr>
								<td colSpan={3} align="center">
									G/Total
								</td>
								<th align="right">{currency.format(getTotal(billsSummary))}</th>
							</tr>
						</tbody>
					</table>
				)}
			</div>
		);
	});
	return (
		<Box className="flex items-center">
			<CustomModal
				action={<Button startIcon={<VisibilityIcon />}>View Report</Button>}
			>
				<Box
					sx={{
						"& table": {
							width: "100%",
							borderCollapse: "collapse",
							fontFamily: "Inter",
							"& td,th": { border: "1px solid #ddd", p: 1 },
						},
					}}
				>
					<div>
						<PrintTable ref={pettyCashTableRef} />
						<ReactToPrint
							trigger={() => (
								<Button sx={{ mt: 2, color: "#fff" }} variant="contained">
									Print
								</Button>
							)}
							content={() => pettyCashTableRef.current}
							onBeforePrint={() => {
								document.title = fileName;
							}}
							onAfterPrint={() => {
								document.title = title;
							}}
						/>
						<DownloadExcel
							header={["DATE", "INVOICE", "SUPPLIER", "AMOUNT (RM)", "STATUS"]}
							body={excelBody || []}
							fileName={fileName}
						/>
						{/* <DownloadTableExcel
              filename="purchase"
              sheet="sheet1"
              currentTableRef={tableRef.current}
            >
              <Button
                sx={{ mt: 2, ml: 1, color: "#fff" }}
                variant="contained"
              >
                Download Excel
              </Button>
            </DownloadTableExcel> */}
					</div>
				</Box>
			</CustomModal>
			<>
				<Box sx={{ display: "none" }}>
					<PrintTable ref={pettyCashTableRef2} />
				</Box>
				<ReactToPrint
					trigger={() => (
						<IconButton>
							<PrintIcon />{" "}
						</IconButton>
					)}
					content={() => pettyCashTableRef2.current}
					onBeforePrint={() => {
						document.title = fileName;
					}}
					onAfterPrint={() => {
						document.title = title;
					}}
				/>
			</>
		</Box>
	);
};

export default BillsViewReport;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	addNewStocks,
	bulkUpdate,
	deleteStock,
	editStock,
	getAllListStock,
	getAllStocks,
} from "./stocksAPI";

const initialState = {
	stocks: [],
	stockDetails: {},
	allStock: [],
	isDetailsLoading: false,
	isBulkUpdating: false,
	total: 0,
	isLoading: false,
	isError: false,
	fetch: 0,
};

export const getStocks = createAsyncThunk("stocks/list", async (params) => {
	const data = await getAllStocks(params);
	return data;
});
export const getStockList = createAsyncThunk(
	"stocks/list-all",
	async (params) => {
		const data = await getAllListStock(params);
		return data;
	},
);

export const addStocks = createAsyncThunk(
	"stocks/create",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await addNewStocks(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
export const removeStock = createAsyncThunk(
	"stocks/delete",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await deleteStock(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
export const updateStock = createAsyncThunk(
	"stocks/edit",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await editStock(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const bulkUpdateStocks = createAsyncThunk(
	"stocks/bulk-update",
	async (payload) => {
		const data = await bulkUpdate(payload);
		return data;
	},
);

const outletReducer = createSlice({
	name: "stocks",
	initialState,

	extraReducers: (builder) => {
		builder
			.addCase(getStocks.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(getStocks.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.stocks = payload.data?.stocks;
				state.total = payload.data?.total;
			})
			.addCase(getStocks.rejected, (state, { error }) => {
				state.isLoading = false;
			});
		builder
			.addCase(getStockList.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(getStockList.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.allStock = payload.allStock;
			})
			.addCase(getStockList.rejected, (state, { error }) => {
				state.isLoading = false;
			});

		builder
			.addCase(addStocks.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(addStocks.fulfilled, (state) => {
				state.isLoading = false;
				state.fetch += 1;
				localStorage.removeItem("stocks");
				localStorage.removeItem("stockOptions");
			})
			.addCase(addStocks.rejected, (state) => {
				state.isLoading = false;
			});
		builder
			.addCase(removeStock.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(removeStock.fulfilled, (state) => {
				state.isLoading = false;
				state.fetch += 1;
			})
			.addCase(removeStock.rejected, (state) => {
				state.isLoading = false;
			});

		builder
			.addCase(updateStock.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(updateStock.fulfilled, (state) => {
				state.isLoading = false;
				state.fetch += 1;
			})
			.addCase(updateStock.rejected, (state) => {
				state.isLoading = false;
			});

		builder
			.addCase(bulkUpdateStocks.pending, (state) => {
				state.isBulkUpdating = true;
			})
			.addCase(bulkUpdateStocks.fulfilled, (state) => {
				state.isBulkUpdating = false;
				state.fetch += 1;
			})
			.addCase(bulkUpdateStocks.rejected, (state) => {
				state.isBulkUpdating = false;
			});
	},
});

export default outletReducer.reducer;

import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
	Box,
	Button,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { getStockReport } from "../../../../redux/stockUsage/stockUsageSlice";
import { getMonthStartEnd } from "../../../../utils/getMonthDates";
import CustomModal from "../../../common/CustomModal";

const getMonthlyData = (data, year, month) => {
	const dates = getMonthStartEnd(year, month);
	const startDay = new Date(dates.firstDate).getDate();
	const endDay = new Date(dates.lastDate).getDate();
	const monthArray = [];
	for (let i = startDay; i <= endDay; i++) {
		monthArray.push({ date: i });
	}
	const monthlyData = monthArray.map((singleItem) => {
		const isDataExist = data?.find(
			(item) => new Date(item.date).getDate() === singleItem.date,
		);
		if (isDataExist) {
			return { ...isDataExist, date: singleItem.date };
		}
		return { ...singleItem };
	});
	const result = [];
	for (let i = 0; i < 4; i++) {
		const week = monthlyData.slice(
			i * 7,
			i === 3 ? monthlyData.length : i * 7 + 7,
		);
		result.push(week);
	}
	return result;
};

const ConsumptionViewReport = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { outletDetails } = useSelector((state) => state.outlets);
	const { currentUser } = useSelector((state) => state.auth);
	const page = search?.split("?page=")?.[1];
	const outletID = outletDetails?._id;
	const [month, setMonth] = useState(
		`${new Date().getMonth() + 1}`.padStart(2, "0"),
	);
	const [year, setYear] = useState(new Date().getFullYear());

	const { summary, isLoading } = useSelector((state) => state.stockUsages);

	useEffect(() => {
		const outlet = outletID;
		const params = { outlet, ...getMonthStartEnd(year, month) };
		dispatch(getStockReport(params));
	}, [dispatch, year, month, outletID]);

	const consumptionTableRef = useRef();
	const consumptionTableRef2 = useRef();
	const title = document.title;
	const fileName = `Consumption-${new Date().toLocaleDateString("en-MY")}`;

	const data = getMonthlyData(summary, year, month) || [];

	const getTotal = (arr) => {
		return arr.reduce((acc, curr) => (curr.total || 0) + acc, 0);
	};

	// const excelBody = cashbookSummary?.map(({ _id, cashOut, cashIn }) => {
	//     if (cashIn === 0 && cashOut === 0) {
	//         return null
	//     }
	//     return { date: new Date(_id).toLocaleDateString("en-MY"), cashIn, cashOut, balance: cashIn - cashOut }
	// })

	const PrintTable = React.forwardRef((props, ref) => {
		return (
			<div ref={ref}>
				<Typography
					variant="h3"
					sx={{
						fontSize: 26,
						fontWeight: 600,
						mb: "5px",
						textTransform: "capitalize",
					}}
				>
					{outletDetails?.name} {page ? `> ${page}` : ""}
				</Typography>
				<Typography sx={{ mb: 1 }}>
					User assigned: {currentUser?.firstName} {currentUser?.lastName}
				</Typography>
				<div className="flex space-x-5 mb-5">
					<TextField
						size="small"
						value={month}
						onChange={(e) => setMonth(e.target.value)}
						sx={{ width: 140 }}
						select
					>
						<MenuItem value="01">January</MenuItem>
						<MenuItem value="02">February</MenuItem>
						<MenuItem value="03">March</MenuItem>
						<MenuItem value="04">April</MenuItem>
						<MenuItem value="05">May</MenuItem>
						<MenuItem value="06">June</MenuItem>
						<MenuItem value="07">July</MenuItem>
						<MenuItem value="08">August</MenuItem>
						<MenuItem value="09">September</MenuItem>
						<MenuItem value="10">October</MenuItem>
						<MenuItem value="11">November</MenuItem>
						<MenuItem value="12">December</MenuItem>
					</TextField>
					<TextField
						size="small"
						value={year}
						onChange={(e) => setYear(e.target.value)}
						sx={{ width: 140 }}
						select
					>
						{[...new Array(5).keys()].map((_e, i) => {
							const year = new Date().getFullYear();
							return (
								<MenuItem value={year - i} key={year - i}>
									{year - i}
								</MenuItem>
							);
						})}
					</TextField>
				</div>
				{summary.length === 0 ? (
					<Typography variant="h3" sx={{ textAlign: "center", my: 10 }}>
						No Data Found!
					</Typography>
				) : (
					<table>
						<thead>
							<tr>
								<th align="center"> Date </th>
								<th align="center"> TTL CONSUMPTION FOR KITCHEN </th>
							</tr>
						</thead>
						<tbody>
							{data?.map((weeklyData, i) => {
								const subTotal = weeklyData?.reduce(
									(prev, curr) => prev + (curr.total || 0),
									0,
								);

								return (
									<Fragment key={i}>
										{weeklyData.map(({ date, total }) => (
											<tr key={date}>
												<td align="center">{date}</td>
												<td align="center">{total || ""}</td>
											</tr>
										))}
										<tr>
											<td align="center">S/TTL</td>
											<th align="center">{subTotal}</th>
										</tr>
									</Fragment>
								);
							})}
							<tr>
								<td align="center">G/TTL</td>
								<th align="center">{getTotal(summary)}</th>
							</tr>
						</tbody>
					</table>
				)}
			</div>
		);
	});
	return (
		<Box className="flex items-center">
			<CustomModal
				action={<Button startIcon={<VisibilityIcon />}>View Report</Button>}
			>
				<Box
					sx={{
						"& table": {
							width: "100%",
							borderCollapse: "collapse",
							fontFamily: "Inter",
							"& td,th": { border: "1px solid #ddd", p: 1 },
						},
					}}
				>
					<div>
						<PrintTable ref={consumptionTableRef} />
						<ReactToPrint
							trigger={() => (
								<Button sx={{ mt: 2, color: "#fff" }} variant="contained">
									Print
								</Button>
							)}
							content={() => consumptionTableRef.current}
							onBeforePrint={() => {
								document.title = fileName;
							}}
							onAfterPrint={() => {
								document.title = title;
							}}
						/>

						{/* <DownloadExcel
                            header={["DATE", "AMOUNT (IN) (RM)", "AMOUNT (OUT) (RM)", "BALANCE"]}
                            body={excelBody || []}
                            fileName={fileName}
                        /> */}
					</div>
				</Box>
			</CustomModal>
			<>
				<Box sx={{ display: "none" }}>
					<PrintTable ref={consumptionTableRef2} />
				</Box>
				<ReactToPrint
					trigger={() => (
						<IconButton>
							<PrintIcon />{" "}
						</IconButton>
					)}
					content={() => consumptionTableRef2.current}
					onBeforePrint={() => {
						document.title = fileName;
					}}
					onAfterPrint={() => {
						document.title = title;
					}}
				/>
			</>
		</Box>
	);
};

export default ConsumptionViewReport;

import { Paper } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import currency from "../../../utils/currencyFormat";
const DashboardItemChart = ({ chartData = [], name = "", chartRef }) => {
	const state = {
		series: [
			{
				name,
				data: chartData || [],
			},
		],
		options: {
			chart: {
				type: "area",
				stacked: false,
				height: 350,
				zoom: {
					type: "x",
					enabled: true,
					autoScaleYaxis: true,
				},
				toolbar: {
					autoSelected: "zoom",
				},
			},
			dataLabels: {
				enabled: false,
			},
			markers: {
				size: 0,
			},
			title: {
				text: name,
				align: "left",
			},
			yaxis: {
				title: {
					text: "Price",
				},
				labels: {
					formatter: (value) => {
						return currency.format(value || 0);
					},
				},
			},
			xaxis: {
				type: "datetime",
			},
			tooltip: {
				x: {
					format: "dd MMM yyyy",
				},
			},
			fill: {
				type: "gradient",
				gradient: {
					shadeIntensity: 0.5,
					opacityFrom: 0.7,
					opacityTo: 0.9,
					stops: [0, 100],
				},
			},
		},
	};

	return (
		<Paper
			sx={{
				p: 2,
				boxShadow: "0px 0px 51px 5px rgba(0, 0, 0, 0.04)",
				borderRadius: "10px",
			}}
		>
			<ReactApexChart
				ref={chartRef}
				options={state.options}
				series={state.series}
				type="area"
				height={300}
			/>
		</Paper>
	);
};

export default DashboardItemChart;

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Outlet } from "react-router-dom";
import NavigationMenu from "../components/dashboard/NavigationMenu";
import logo from "./../assets/images/logo/Caterpro_logo.svg";

const drawerWidth = 250;

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

function DashboardContent() {
	// const { pathname } = useLocation();
	const [open, setOpen] = React.useState(true);
	const toggleDrawer = () => {
		setOpen(!open);
	};

	return (
		<Box sx={{ display: "flex" }}>
			<Drawer variant="permanent" open={open} className="hidden lg:block">
				<Toolbar
					sx={{
						height: 79,
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						px: [1],
					}}
				>
					<Link href="/dashboard" mr={3}>
						<img width={100} height={50} src={logo} alt="Logo" />
					</Link>
					<IconButton
						sx={{
							...(!open && { display: "none" }),
						}}
						onClick={toggleDrawer}
					>
						<ChevronLeftIcon />
					</IconButton>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer}
						sx={{
							mx: "10px",
							...(open && { display: "none" }),
						}}
					>
						<MenuIcon />
					</IconButton>
				</Toolbar>
				<Divider />
				<List sx={{ pt: 0 }} component="nav">
					<NavigationMenu open={open} />
				</List>
			</Drawer>

			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === "light"
							? theme.palette.grey[100]
							: theme.palette.grey[900],
					flexGrow: 1,
					height: "100vh",
					overflow: "auto",
				}}
			>
				<Outlet />
			</Box>
		</Box>
	);
}

export default function DashboardLayout() {
	return <DashboardContent />;
}

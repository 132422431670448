import { axiosTokenInstance } from "../../utils/axios";

export const getAllStocks = async (params) => {
	const { data } = await axiosTokenInstance.get("stocks/list", {
		params: {
			...params,
		},
	});
	return data;
};
export const getAllListStock = async (params) => {
	const { data } = await axiosTokenInstance.get("stocks/list-all", {
		params: {
			...params,
		},
	});
	return data;
};
export const addNewStocks = async (payload) => {
	const { data } = await axiosTokenInstance.post("stocks/create", payload);
	return data;
};
export const bulkUpdate = async (payload) => {
	const { data } = await axiosTokenInstance.patch(
		"stocks/bulk-update",
		payload,
	);
	return data;
};

export const deleteStock = async (payload) => {
	const { data } = await axiosTokenInstance.post("stocks/delete", payload);
	return data;
};
export const editStock = async (payload) => {
	const { data } = await axiosTokenInstance.patch(
		`stocks/edit/${payload.id}`,
		payload.data,
	);
	return data;
};
export const getOpeningStock = async (params) => {
	const { data } = await axiosTokenInstance.get("stocks/opening", {
		params: {
			...params,
		},
	});
	return data;
};

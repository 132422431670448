import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updatePettyCash } from "../../../../redux/pettycashes/pettycashesSlice";
import { twoDecimalValidator } from "../../../../utils/twoDecimalValidator";
import PrimaryButton from "../../../common/PrimaryButton";

const PettyCashForm = ({ pettycash, edit, categories }) => {
	const dispatch = useDispatch();
	const {
		_id,
		date,
		pvc,
		category: pettyCashCategory,
		description,
		amount,
		outlet,
	} = pettycash || {};

	const [category, setCategory] = React.useState("all");

	useEffect(() => {
		setCategory(pettyCashCategory._id);
	}, [pettycash]);

	const submitHandler = (e) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const data = {
			pvc: formData.get("pvc"),
			date: formData.get("date"),
			category: category,
			description: formData.get("description"),
			amount: formData.get("amount"),
			outlet: outlet,
		};
		if (edit) {
			dispatch(updatePettyCash({ id: _id, data }));
		}
	};

	return (
		<Box sx={{ p: 3 }} component={"form"} onSubmit={submitHandler}>
			<Grid container spacing={2}>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Invoice Number.
					</Typography>
					<TextField
						size="small"
						margin="dense"
						name="pvc"
						type="text"
						fullWidth
						required
						defaultValue={edit && pvc}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>Date</Typography>
					<TextField
						defaultValue={
							edit
								? new Date(date).toISOString().split("T")[0]
								: new Date().toISOString().split("T")[0]
						}
						type="date"
						required
						size="small"
						margin="dense"
						fullWidth
						name="date"
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Category
					</Typography>
					<TextField
						required
						onChange={(e) => setCategory(e.target.value)}
						select
						size="small"
						margin="dense"
						name="category"
						fullWidth
						defaultValue={edit ? pettyCashCategory._id : "all"}
					>
						<MenuItem value="all">All</MenuItem>
						{categories?.map(({ _id, name }) => (
							<MenuItem key={_id} value={_id}>
								{" "}
								{name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Description
					</Typography>
					<TextField
						size="small"
						margin="dense"
						name="description"
						type="text"
						fullWidth
						required
						defaultValue={edit && description}
					/>
				</Grid>

				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Amount (RM)
					</Typography>
					<TextField
						size="small"
						margin="dense"
						name="amount"
						type="number"
						fullWidth
						required
						inputProps={{ step: 0.01 }}
						onChange={twoDecimalValidator}
						defaultValue={edit && amount}
					/>
				</Grid>
			</Grid>

			<Box sx={{ textAlign: "right", mt: 3 }}>
				<PrimaryButton sx={{ width: 150 }} type="submit">
					Edit Petty Cash
				</PrimaryButton>
			</Box>
		</Box>
	);
};

export default PettyCashForm;

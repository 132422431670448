import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	addNewSales,
	bulkUpdate,
	deleteSales,
	editSales,
	getAllListSales,
	getAllSales,
	getSaleSummary,
} from "./salesAPI";

const initialState = {
	salesSummary: [],
	sales: [],
	allSales: [],
	salesDetails: {},
	isSalesSummaryLoading: false,
	isDetailsLoading: false,
	isBulkUpdating: false,
	total: 0,
	isLoading: false,
	isError: false,
	fetch: 0,
};

export const getSales = createAsyncThunk("sales/list", async (params) => {
	const data = await getAllSales(params);
	return data;
});
export const getSalesList = createAsyncThunk(
	"sales/list-all",
	async (params) => {
		const data = await getAllListSales(params);
		return data;
	},
);

export const getSalesSummary = createAsyncThunk(
	"sales/summary",
	async (params) => {
		const data = await getSaleSummary(params);
		return data;
	},
);

export const addSales = createAsyncThunk(
	"sales/create",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await addNewSales(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
export const updateSale = createAsyncThunk(
	"sales/edit",
	async (payload, { rejectWithValue }) => {
		try {
			const data = await editSales(payload);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);
export const removeSale = createAsyncThunk(
	"sales/delete",
	async (id, { rejectWithValue }) => {
		try {
			const data = await deleteSales(id);
			return data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const bulkUpdateSales = createAsyncThunk(
	"sales/bulk-update",
	async (payload) => {
		const data = await bulkUpdate(payload);
		return data;
	},
);

const outletReducer = createSlice({
	name: "sales",
	initialState,

	extraReducers: (builder) => {
		builder
			.addCase(getSales.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(getSales.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.sales = payload.data?.sales;
				state.total = payload.data?.total;
			})
			.addCase(getSales.rejected, (state, { error }) => {
				state.isLoading = false;
			});

		builder
			.addCase(getSalesList.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(getSalesList.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.allSales = payload.allSales;
			})
			.addCase(getSalesList.rejected, (state, { error }) => {
				state.isLoading = false;
			});

		builder
			.addCase(addSales.pending, (state) => {
				state.isError = false;
				state.isLoading = true;
			})
			.addCase(addSales.fulfilled, (state) => {
				state.isLoading = false;
				state.fetch += 1;
			})
			.addCase(addSales.rejected, (state) => {
				state.isLoading = false;
			});
		builder.addCase(removeSale.fulfilled, (state) => {
			state.fetch += 1;
		});
		builder.addCase(updateSale.fulfilled, (state) => {
			state.fetch += 1;
		});

		builder
			.addCase(bulkUpdateSales.pending, (state) => {
				state.isBulkUpdating = true;
			})
			.addCase(bulkUpdateSales.fulfilled, (state, { payload }) => {
				state.isBulkUpdating = false;
				state.fetch += 1;
			})
			.addCase(bulkUpdateSales.rejected, (state, { error }) => {
				state.isBulkUpdating = false;
			});

		builder
			.addCase(getSalesSummary.pending, (state) => {
				state.isError = false;
				state.isSalesSummaryLoading = true;
			})
			.addCase(getSalesSummary.fulfilled, (state, { payload }) => {
				state.isSalesSummaryLoading = false;
				state.salesSummary = payload.salesSummary;
			})
			.addCase(getSalesSummary.rejected, (state) => {
				state.isSalesSummaryLoading = false;
			});
	},
});

export default outletReducer.reducer;

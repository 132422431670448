import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import {
	Box,
	Button,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { closeModal } from "../../../../redux/notification/notificationSlice";
import { getOutlets } from "../../../../redux/outlets/outletsSlice.js";
import { getAllUnit } from "../../../../redux/productUnit/productUnitSlice";
import { getAllProduct } from "../../../../redux/products/productsSlice";
import {
	addStockUsage,
	getStockUsages,
} from "../../../../redux/stockUsage/stockUsageSlice";
import { getStocks } from "../../../../redux/stocks/stocksSlice";
import currency from "../../../../utils/currencyFormat";
import CustomModal from "../../../common/CustomModal";
import DataTable from "../../../common/DataTable";
import FullScreenDialog from "../../../common/FullScreenDialog";
import PrimaryButton from "../../../common/PrimaryButton";
import ActionMenu from "../ActionMenu.js";
import AddStockUsages from "./AddStockUsages";
import AddStocks from "./AddStocks";
import ConsumptionViewReport from "./ConsumptionViewReport.js";
import ConsumptionViewReport2 from "./ConsumptionViewReport2.js";

const headings = [
	{
		id: "sl",
		align: "left",
		label: "#",
	},
	{
		id: "date",
		align: "left",
		label: "DATE",
		required: true,
	},
	{
		id: "product",
		align: "left",
		label: "PRODUCT NAME",
	},
	// {
	//     id: "unit",
	//     align: "left",
	//     label: "UNIT"
	// },
	{
		id: "openingStock",
		align: "right",
		label: "OPENING STOCK (os)",
	},
	// {
	//     id: 'purchase',
	//     align: "left",
	//     label: 'PURCHASES (p)',
	// },
	{
		id: "closingStock",
		align: "right",
		label: "CLOSING STOCK (cs)",
	},
	{
		id: "price",
		align: "right",
		label: "PRICE PER UNIT (RM)",
	},
	{
		id: "action",
		align: "left",
		label: "Action",
	},
	// {
	//     id: 'consumption',
	//     align: "right",
	//     label: 'CONSUMPTION (os + p - cs)',
	// },
];

const stockUsageHeading = [
	{
		id: "sl",
		align: "left",
		label: "#",
	},
	{
		id: "date",
		align: "left",
		label: "DATE",
		required: true,
	},
	{
		id: "product",
		align: "left",
		label: "PRODUCT",
	},
	{
		id: "quantity",
		align: "right",
		label: "QUANTITY",
	},
	{
		id: "price",
		align: "right",
		label: "PRICE PER UNIT (RM)",
	},
	{
		id: "action",
		align: "left",
		label: "Action",
	},
];

const StocksTable = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { stocks, total, isLoading, fetch, isBulkUpdating } = useSelector(
		(state) => state.stocks,
	);
	const { fetch: productFetch } = useSelector((state) => state.products);
	const {
		stockUsages,
		total: usageTotal,
		isLoading: usagesLoading,
		fetch: usagesFetch,
	} = useSelector((state) => state.stockUsages);
	const [selectedStock, setSelectedStock] = useState("stockDailyUsage");
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [dateFilter, setDateFilter] = useState({});

	const handleDateFilter = (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const values = {
			startDate: data.get("startDate"),
			endDate: data.get("endDate"),
		};

		setDateFilter(values);
		dispatch(closeModal());
	};

	useEffect(() => {
		dispatch(getOutlets());
		dispatch(getAllUnit());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getAllProduct());
	}, [dispatch, productFetch]);

	// stock take
	useEffect(() => {
		const params = {
			page: page,
			size: rowsPerPage,
			outlet: id,
			...dateFilter,
		};

		dispatch(getStocks(params));
	}, [dispatch, page, fetch, id, dateFilter, rowsPerPage]);

	// stock daily usage
	useEffect(() => {
		const params = {
			page: page,
			size: rowsPerPage,
			outlet: id,
			...dateFilter,
		};

		dispatch(getStockUsages(params));
	}, [dispatch, page, usagesFetch, id, dateFilter, rowsPerPage]);

	useEffect(() => {
		setPage(1);
	}, [rowsPerPage, selectedStock]);

	const addStocksUsageHandler = (e) => {
		e.preventDefault();
		const data = new FormData(e.currentTarget);
		const formData = {
			date: data.get("date"),
			product: data.get("product"),
			quantity: data.get("quantity"),
			price: data.get("price"),
			outlet: id,
		};
		if (data.get("quantity") < 0) {
			alert("Value cannot be less than 0");
			return;
		}
		dispatch(addStockUsage(formData));
	};
	return (
		<Box className="space-y-2.5">
			{selectedStock === "stockTake" ? (
				<Box className="flex justify-end md:hidden ">
					<ConsumptionViewReport2 dateFilter={dateFilter} category="all" />
				</Box>
			) : (
				<Box className="flex justify-end md:hidden ">
					<ConsumptionViewReport dateFilter={dateFilter} category="all" />
				</Box>
			)}

			<Box className="min-[500px]:flex min-[500px]:justify-between items-center min-[500px]:space-y-0 space-y-2.5 ">
				<Box
					sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
				>
					<CustomModal
						sx={{ maxWidth: 300 }}
						title="Date Filter"
						action={
							<Button
								variant="outlined"
								sx={{
									textTransform: "capitalize",
									color: "gray",
									borderColor: "#0000003b",
									"&:hover": { borderColor: "secondary.main" },
								}}
							>
								Filter Date
							</Button>
						}
					>
						<Box component={"form"} onSubmit={handleDateFilter}>
							<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
								Start Date
							</Typography>
							<TextField
								defaultValue={
									dateFilter.startDate || new Date().toISOString().split("T")[0]
								}
								type="date"
								required
								size="small"
								margin="dense"
								fullWidth
								name="startDate"
							/>
							<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
								End Date
							</Typography>
							<TextField
								defaultValue={
									dateFilter.endDate || new Date().toISOString().split("T")[0]
								}
								type="date"
								required
								size="small"
								margin="dense"
								fullWidth
								name="endDate"
							/>
							<PrimaryButton sx={{ mt: 2 }} type="submit">
								Filter
							</PrimaryButton>
						</Box>
					</CustomModal>
					{dateFilter.startDate && (
						<IconButton onClick={() => setDateFilter({})}>
							<Close />
						</IconButton>
					)}
				</Box>

				<Box className="flex space-x-2.5 justify-end">
					<CustomModal
						title={"Add a Stock Usage"}
						action={
							<Button
								variant="contained"
								startIcon={<AddIcon />}
								sx={{ color: "#fff", boxShadow: "none", mr: 1 }}
							>
								stock usage
							</Button>
						}
					>
						<AddStockUsages handleSubmit={addStocksUsageHandler} />
					</CustomModal>
					<FullScreenDialog
						title={"Add Stocks"}
						action={
							<Button startIcon={<AddIcon />} variant="outlined">
								{" "}
								Stock take
							</Button>
						}
					>
						<AddStocks
							outlet={id}
							// rows={rows}
							// setRows={setRows}
							// allOptions={options}
							// options={productOptions}
							// setOptions={setProductOptions}
						/>
					</FullScreenDialog>
				</Box>
			</Box>
			<Box>
				<RadioGroup
					defaultValue="stockDailyUsage"
					onChange={(e) => setSelectedStock(e.target.value)}
				>
					<Box sx={{ display: "flex" }}>
						<FormControlLabel
							value="stockDailyUsage"
							control={<Radio name="stock" />}
							label="Stock daily usage"
						/>
						<FormControlLabel
							value="stockTake"
							control={<Radio name="stock" />}
							label="Stock take"
						/>
					</Box>
				</RadioGroup>
			</Box>
			{selectedStock === "stockTake" ? (
				<DataTable
					height="calc(100vh - 440px)"
					headings={headings}
					rows={stocks}
					total={total}
					page={page}
					loading={isBulkUpdating || isLoading}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
					setPage={setPage}
					updateFields={{
						outlet: (item) => item.outlet,
						product: (item) => item?.product?.name,
						price: (item) => <>{currency.format(item?.product?.price)}</>,
						date: (item) => (
							<>{new Date(item.date).toLocaleDateString("en-MY")}</>
						),
						action: (item) => (
							<ActionMenu id={id} item={item} title="Stock" edit />
						),
					}}
				/>
			) : (
				<DataTable
					height="calc(100vh - 440px)"
					headings={stockUsageHeading}
					rows={stockUsages}
					total={usageTotal}
					page={page}
					loading={usagesLoading}
					rowsPerPage={rowsPerPage}
					setRowsPerPage={setRowsPerPage}
					setPage={setPage}
					updateFields={{
						product: (item) => item.product?.name,
						date: (item) => (
							<>{new Date(item.date).toLocaleDateString("en-MY")}</>
						),
						price: (item) => <>{currency.format(item.price)}</>,
						action: (item) => (
							<ActionMenu id={item._id} item={item} title="Stock Usage" edit />
						),
						quantity: (item) => {
							return `${item.quantity} ${item.unit}`;
						},
					}}
				/>
			)}
		</Box>
	);
};

export default StocksTable;

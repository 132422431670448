import Close from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import {
	Box,
	Button,
	Grid,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { addBill, updateBill } from "../../../../redux/bills/billsSlice";
import { twoDecimalValidator } from "../../../../utils/twoDecimalValidator";
import PrimaryButton from "../../../common/PrimaryButton";

function getImageFilename(url) {
	// Split the URL by slashes to extract the filename
	const parts = url.split("/");
	// Get the last part of the URL, which should be the filename
	const filename = parts[parts.length - 1];
	// Return the filename
	return filename;
}

const AddBill = ({ suppliers = [], edit, bill, paramId }) => {
	const dispatch = useDispatch();
	const { amount, status, date, invoice, supplier, photo, _id } = bill || {};
	const [newPhoto, setNewPhoto] = useState(null);
	const imageRef = useRef();
	const [uploadPhoto, setUploadPhoto] = useState(false);
	const clearImage = () => {
		setNewPhoto(null);
		setUploadPhoto(true);
	};

	const billHandler = (e) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		if (edit) {
			if (newPhoto) {
				formData.append("attachment", newPhoto);
			}
			dispatch(updateBill({ id: _id, data: formData }));
		} else {
			// add new
			formData.append("outlet", paramId);
			formData.append("id", new Date().getTime());
			dispatch(addBill(formData));
		}
	};

	return (
		<Box component={"form"} onSubmit={billHandler}>
			<Grid container spacing={2}>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>Date</Typography>
					<TextField
						defaultValue={
							edit
								? new Date(date).toISOString().split("T")[0]
								: new Date().toISOString().split("T")[0]
						}
						type="date"
						required
						size="small"
						margin="dense"
						fullWidth
						name="date"
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Supplier
					</Typography>

					<TextField
						required
						size="small"
						margin="dense"
						select
						fullWidth
						name="supplier"
						defaultValue={(edit && supplier._id) || ""}
					>
						<MenuItem value="" disabled>
							Select Supplier
						</MenuItem>
						{suppliers.map(({ _id, name }) => (
							<MenuItem value={_id} key={_id}>
								{name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Amount (RM)
					</Typography>
					<TextField
						size="small"
						margin="dense"
						name="amount"
						type="number"
						fullWidth
						required
						inputProps={{ step: 0.01 }}
						onChange={twoDecimalValidator}
						defaultValue={edit && amount}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>
						Invoice No.
					</Typography>
					<TextField
						size="small"
						margin="dense"
						name="invoice"
						type="text"
						fullWidth
						required
						defaultValue={edit && invoice}
					/>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>Status</Typography>
					<TextField
						required
						size="small"
						margin="dense"
						select
						fullWidth
						name="status"
						defaultValue={edit ? status : "submitted"}
					>
						<MenuItem value="" disabled>
							Select Status
						</MenuItem>
						<MenuItem value={"pending"}>Pending</MenuItem>
						<MenuItem value={"submitted"}>Submitted</MenuItem>
						<MenuItem value={"incorrect-invoice"}>Incorrect Invoice</MenuItem>
						<MenuItem value={"delivery-not-fullfil"}>
							Delivery Not Fullfil
						</MenuItem>
						<MenuItem value={"wrong-outlet"}>Wrong Outlet</MenuItem>
					</TextField>
				</Grid>
				<Grid item md={6} xs={12}>
					<Typography sx={{ fontSize: 18, fontWeight: 500, mb: 1 }}>
						Attachment (optional)
					</Typography>
					{(!edit || uploadPhoto) && (
						<Button
							startIcon={<UploadIcon />}
							variant="outlined"
							component="label"
						>
							Upload file
							<input
								hidden
								accept="image/png,image/jpeg,image/jpg,application/pdf"
								type="file"
								name="attachment"
								ref={imageRef}
								onChange={(e) => {
									setNewPhoto(e.target.files[0]);
									setUploadPhoto(false);
								}}
							/>
						</Button>
					)}
					{photo && !uploadPhoto && !newPhoto && (
						<Typography>
							{getImageFilename(photo)}{" "}
							<IconButton onClick={clearImage}>
								<Close />
							</IconButton>
						</Typography>
					)}

					{newPhoto && (
						<Typography>
							{newPhoto.name}{" "}
							<IconButton onClick={clearImage}>
								<Close />
							</IconButton>
						</Typography>
					)}
				</Grid>
			</Grid>

			<Box sx={{ textAlign: "right", mt: 3 }}>
				<PrimaryButton sx={{ width: 150 }} type="submit">
					Add Purchase
				</PrimaryButton>
			</Box>
		</Box>
	);
};

export default AddBill;

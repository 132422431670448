import axios from "axios";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_BASE,
});

export const axiosTokenInstance = axios.create({
	baseURL: process.env.REACT_APP_API_BASE,
});

axiosTokenInstance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers["x-auth-token"] = token;
		}
		return config;
	},
	(error) => Promise.reject(error),
);

import { axiosTokenInstance } from "../../utils/axios";

export const getAllUsages = async (params) => {
	const { data } = await axiosTokenInstance.get("stock-usages/list", {
		params: {
			...params,
		},
	});
	return data;
};

export const addNewStockUsage = async (payload) => {
	const { data } = await axiosTokenInstance.post(
		"stock-usages/create",
		payload,
	);
	return data;
};
export const editStockUsage = async (payload) => {
	const { data } = await axiosTokenInstance.patch(
		`stock-usages/edit/${payload.id}`,
		payload.data,
	);
	return data;
};
export const deleteStockUsageItem = async (id) => {
	const { data } = await axiosTokenInstance.delete(`stock-usages/delete/${id}`);
	return data;
};
export const getStockSummary = async (params) => {
	const { data } = await axiosTokenInstance.get("stock-usages/summary", {
		params: {
			...params,
		},
	});
	return data;
};

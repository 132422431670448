import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateStock } from "../../../../redux/stocks/stocksSlice";
import PrimaryButton from "../../../common/PrimaryButton";

const EditStock = ({ item }) => {
	const dispatch = useDispatch();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const date = formData.get("date");
		const closingStock = formData.get("closingStock");

		dispatch(
			updateStock({
				id: item?._id,
				data: {
					date,
					closingStock,
					outlet: item.outlet,
					product: item.product?._id,
				},
			}),
		);
	};

	return (
		<Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
			<TableContainer>
				<Table stickyHeader aria-labelledby="tableTitle">
					<TableHead sx={{ position: "sticky", top: 0, zIndex: 10 }}>
						<TableRow>
							<TableCell>Date </TableCell>
							<TableCell>Product </TableCell>
							<TableCell>Opening Stock </TableCell>
							<TableCell>Closing Stock </TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow hover tabIndex={-1}>
							<TableCell sx={{ py: "5px" }}>
								<TextField
									defaultValue={item.date}
									type="date"
									required
									size="small"
									inputProps={{
										min: new Date(item.date).toLocaleDateString("en-CA"),
									}}
									name="date"
								/>
							</TableCell>
							<TableCell sx={{ py: "5px" }}>
								{item?.product?.name}
								<input type="hidden" name="name" value={item?.product?.name} />
								<input
									type="hidden"
									name="product"
									value={item?.product?._id}
								/>
							</TableCell>
							<TableCell sx={{ py: "5px" }}>
								<TextField value={item.openingStock} size="small" disabled />
								<input
									type="hidden"
									name="openingStock"
									value={item.openingStock}
								/>
							</TableCell>
							<TableCell sx={{ py: "5px" }}>
								<TextField
									defaultValue={item.closingStock}
									size="small"
									name="closingStock"
									required
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<Box sx={{ textAlign: "right", mt: 3 }}>
				<PrimaryButton sx={{ width: 150 }} type="submit">
					Update Stocks
				</PrimaryButton>
			</Box>
		</Box>
	);
};

export default EditStock;

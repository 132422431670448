import { axiosTokenInstance } from "../../utils/axios";

export const getAllSaleProduct = async (params) => {
	const { data } = await axiosTokenInstance.get("saleproduct/list", {
		params: { ...params },
	});
	return data;
};
export const addNewSaleProduct = async (payload) => {
	const { data } = await axiosTokenInstance.post("saleproduct/create", payload);
	return data;
};

export const addNewService = async (payload) => {
	const { data } = await axiosTokenInstance.post(
		"saleproduct/service/create",
		payload,
	);
	return data;
};
export const getAllService = async (params) => {
	const { data } = await axiosTokenInstance.get("saleproduct/service/list", {
		params: { ...params },
	});
	return data;
};
export const addNewGroup = async (payload) => {
	const { data } = await axiosTokenInstance.post(
		"saleproduct/group/create",
		payload,
	);
	return data;
};
export const getAllGroups = async (params) => {
	const { data } = await axiosTokenInstance.get("saleproduct/group/list", {
		params: { ...params },
	});
	return data;
};
export const addNewOffer = async (payload) => {
	const { data } = await axiosTokenInstance.post(
		"saleproduct/offer-type/create",
		payload,
	);
	return data;
};
export const getAllOffers = async (params) => {
	const { data } = await axiosTokenInstance.get("saleproduct/offer-type/list", {
		params: { ...params },
	});
	return data;
};

import Close from "@mui/icons-material/Close";
import {
	Box,
	IconButton,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addPettyCashes } from "../../../../redux/pettycashes/pettycashesSlice";
import currency from "../../../../utils/currencyFormat";
import { twoDecimalValidator } from "../../../../utils/twoDecimalValidator";
import PrimaryButton from "../../../common/PrimaryButton";

const AddPettyCashes = ({ outlet, categories }) => {
	const [rows, setRows] = useState([]);
	const [total, setTotal] = useState(0);
	const dispatch = useDispatch();
	const handleSubmit = (e) => {
		e.preventDefault();
		if (rows.length === 0) return;
		dispatch(addPettyCashes({ pettyCashes: rows, total }));
	};

	const [date, setDate] = useState(new Date().toISOString().split("T")[0]);
	const addRowHandler = () => {
		const id = new Date().getTime(); //rows.length > 0 ? rows?.[rows.length - 1]?.id + 1 : 1;

		setRows((pre) => [
			...pre,
			{
				id,
				outlet,
				pvc: "",
				date,
				category: "",
				description: "",
				amount: "",
			},
		]);
	};
	const CategoryDropDown = ({ onChange, defaultValue }) => {
		return (
			<TextField
				onChange={onChange}
				defaultValue={defaultValue}
				select
				size="small"
				required
				name="category"
				sx={{ minWidth: 150 }}
			>
				{categories?.map(({ _id, name }) => (
					<MenuItem key={_id} value={_id}>
						{" "}
						{name}
					</MenuItem>
				))}
			</TextField>
		);
	};

	const removeRow = (id) => {
		const newRows = rows.filter((row) => row.id !== id);
		setRows(newRows);
		const total = newRows.reduce((pre, curr) => Number(curr.amount) + pre, 0);
		setTotal(total);
	};

	const handleChange = (e, id) => {
		const newRows = rows.map((row) => {
			if (row.id === id) {
				return { ...row, [e.target.name]: e.target.value };
			}
			return row;
		});
		setRows(newRows);
		if (e.target.name === "amount") {
			const total = rows
				.filter((row) => row.id !== id)
				.reduce((pre, curr) => Number(curr.amount) + pre, 0);
			setTotal(total + Number(e.target.value));
		}
	};

	return (
		<Box component={"form"} onSubmit={handleSubmit}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "flex-end",
				}}
			>
				<Box>
					<Typography sx={{ fontSize: 18, fontWeight: 500 }}>Date</Typography>
					<TextField
						value={date}
						onChange={(e) => setDate(e.target.value)}
						type="date"
						required
						size="small"
						margin="dense"
						fullWidth
						name="date"
					/>
				</Box>
				<PrimaryButton onClick={addRowHandler}>Add Row</PrimaryButton>
			</Box>
			<TableContainer>
				<Table stickyHeader aria-labelledby="tableTitle">
					<TableHead sx={{ position: "sticky", top: 0, zIndex: 10 }}>
						<TableRow>
							<TableCell>INVOICE NUMBER </TableCell>
							<TableCell>Date </TableCell>
							<TableCell>Category </TableCell>
							<TableCell>Description </TableCell>
							<TableCell>Amount </TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows?.map((row, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index}>
									<TableCell sx={{ py: "5px" }}>
										<TextField
											onChange={(e) => handleChange(e, row.id)}
											defaultValue={row.pvc}
											type="text"
											required
											size="small"
											name="pvc"
										/>
									</TableCell>
									<TableCell sx={{ py: "5px" }}>
										<TextField
											onChange={(e) => handleChange(e, row.id)}
											defaultValue={date}
											type="date"
											required
											size="small"
											name="date"
										/>
									</TableCell>
									<TableCell sx={{ py: "5px" }}>
										<CategoryDropDown
											onChange={(e) => handleChange(e, row.id)}
											defaultValue={row.category}
										/>
									</TableCell>
									<TableCell sx={{ py: "5px" }}>
										<TextField
											onChange={(e) => handleChange(e, row.id)}
											defaultValue={row.description}
											size="small"
											name="description"
											required
										/>
									</TableCell>
									<TableCell sx={{ py: "5px" }}>
										<Box sx={{ display: "flex", alignItems: "center" }}>
											<TextField
												inputProps={{ step: 0.01 }}
												onChange={(e) => {
													twoDecimalValidator(e);
													handleChange(e, row.id);
												}}
												value={row.amount}
												size="small"
												type="number"
												required
												name="amount"
											/>
											<IconButton
												onClick={() => removeRow(row.id)}
												color="error"
												sx={{ ml: 2 }}
											>
												<Close />
											</IconButton>
										</Box>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>

			<Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
				<Box>
					<Typography
						sx={{ fontSize: 22, fontWeight: 600, mb: 2, textAlign: "right" }}
					>
						Total: {currency.format(total || 0)}
					</Typography>
					<PrimaryButton sx={{ width: 150 }} type="submit">
						Save
					</PrimaryButton>
				</Box>
			</Box>
		</Box>
	);
};

export default AddPettyCashes;

import { login, register, verify } from "../auth/authSlice";
import {
	addBill,
	bulkUpdateBills,
	deleteBill,
	updateBill,
} from "../bills/billsSlice";
import { addDeposit, deleteCashbook } from "../cashbook/cashbookSlice";
import { addOutlet, deleteOutlet, editOutlet } from "../outlets/outletsSlice";
import {
	addPettyCashes,
	deletePettyCash,
	updatePettyCash,
} from "../pettycashes/pettycashesSlice";
import { addNewCategory } from "../productCategory/productCategorySlice";
import {
	addNewProduct,
	deleteProduct,
	updateProduct,
} from "../products/productsSlice";

import { addNewUnit } from "../productUnit/productUnitSlice";
import {
	addGroup,
	addOffer,
	addSaleProduct,
	addService,
} from "../saleproduct/saleproductSlice";
import { addSales, removeSale, updateSale } from "../sales/salesSlice";
import {
	addStockUsage,
	deleteStockUsage,
	updateStockUsage,
} from "../stockUsage/stockUsageSlice";
import { addStocks, removeStock, updateStock } from "../stocks/stocksSlice";
import {
	addNewSupplier,
	deleteSupplier,
	updateSupplier,
} from "../suppliers/suppliersSlice";
import { addUser, deleteSingleUser } from "../users/userSlice";
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
	notifications: [],
	isOpen: false,
	modalClose: false,
	isEditMode: false,
};

const notificationReducer = createSlice({
	name: "notification",
	initialState,
	reducers: {
		setNotification: (state, { payload }) => {
			state.notifications.push(payload);
			state.isOpen = true;
		},
		closeNotification: (state) => {
			state.isOpen = false;
		},
		updateModalStatus: (state) => {
			state.modalClose = false;
		},
		closeModal: (state) => {
			state.modalClose = true;
		},
		enableEditMode: (state) => {
			state.isEditMode = true;
		},
		disableEditMode: (state) => {
			state.isEditMode = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(login.rejected, (state, { payload }) => {
			state.notifications.push({
				severity: "error",
				message: payload.message,
			});
			state.isOpen = true;
		});
		builder
			.addCase(register.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(register.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addUser.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addUser.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});

		builder
			.addCase(deleteSingleUser.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(deleteSingleUser.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(verify.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(verify.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addOutlet.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addOutlet.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(editOutlet.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(editOutlet.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(deleteOutlet.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(deleteOutlet.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addBill.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addBill.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(updateBill.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.isEditMode = false;
			})
			.addCase(updateBill.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});

		builder
			.addCase(bulkUpdateBills.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(bulkUpdateBills.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});

		builder
			.addCase(addNewSupplier.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addNewSupplier.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(updateSupplier.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(updateSupplier.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(deleteSupplier.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(deleteSupplier.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addStocks.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addStocks.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(updateStock.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(updateStock.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(removeStock.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(removeStock.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addStockUsage.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addStockUsage.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(updateStockUsage.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(updateStockUsage.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(deleteStockUsage.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(deleteStockUsage.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addPettyCashes.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addPettyCashes.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(updatePettyCash.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.isEditMode = false;
			})
			.addCase(updatePettyCash.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(deletePettyCash.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(deletePettyCash.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addNewProduct.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addNewProduct.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(updateProduct.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.isEditMode = false;
			})
			.addCase(updateProduct.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});

		builder
			.addCase(addNewCategory.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(addNewCategory.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addNewUnit.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(addNewUnit.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});

		builder
			.addCase(deleteProduct.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(deleteProduct.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addSales.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addSales.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(updateSale.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(updateSale.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(removeSale.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(removeSale.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addSaleProduct.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(addSaleProduct.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addService.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(addService.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addGroup.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(addGroup.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addOffer.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
			})
			.addCase(addOffer.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(addDeposit.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(addDeposit.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
		builder
			.addCase(deleteCashbook.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(deleteCashbook.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});

		builder
			.addCase(deleteBill.fulfilled, (state, { payload }) => {
				state.notifications.push({
					severity: "success",
					message: payload.message,
				});
				state.isOpen = true;
				state.modalClose = true;
			})
			.addCase(deleteBill.rejected, (state, { payload }) => {
				state.notifications.push({
					severity: "error",
					message: payload.message,
				});
				state.isOpen = true;
			});
	},
});

export const {
	setNotification,
	closeNotification,
	updateModalStatus,
	closeModal,
	enableEditMode,
	disableEditMode,
} = notificationReducer.actions;

export default notificationReducer.reducer;
